<template>
    <zw-sidebar @shown="shown" :title="$t('settings.warehouse.title')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">

                <b-row>
                    <b-col cols="12" class="text-right">
                        <b-button
                            @click="$root.$children[0].openModal('generate-racks-modal', {id: payload.id},shown,{width: '800px'})"
                            variant="primary"
                            class="mb-2"
                        >
                            <font-awesome-icon icon="plus"/>
                            {{ $t('common.button.create') }}
                        </b-button>
                    </b-col>
                </b-row>

                <b-table id="table"
                         ref="table"
                         striped
                         hover
                         sort-icon-right
                         no-provider-sorting
                         custom-foot
                         head-row-variant="info"
                         foot-row-variant="info"
                         :items="data"
                         :fields="fields"
                >
                    <template #cell(actions)="row">
                        <b-button
                            @click="row.toggleDetails"
                            size="sm"
                            variant="info"
                            class="mr-2"
                        >
                            <font-awesome-icon icon="eye"/>
                        </b-button>

                        <b-button
                            @click="createShelves(row.item)"
                            variant="primary"
                            size="sm"
                            class="mr-2"
                        >
                            <font-awesome-icon icon="plus"/>
                        </b-button>

                        <b-button @click="deleteRack(row.item)"
                                  size="sm"
                                  variant="danger"
                                  :title="$t('common.title.delete')"
                        >
                            <font-awesome-icon icon="trash"/>
                        </b-button>
                    </template>
                    <template #row-details="row">
                        <b-table-simple bordered>
                            <b-thead head-variant="info">
                                <b-th>{{ $t('stock.label.shelf_name') }}</b-th>
                                <b-th>{{ $t('stock.label.shelf_num') }}</b-th>
                                <b-th>{{ $t('stock.label.capacity') }}</b-th>
                                <b-th>{{ $t('stock.label.enabled') }}</b-th>
                                <b-th>{{ $t('stock.label.actions') }}</b-th>
                            </b-thead>
                            <tbody>
                            <b-tr v-for="shelve in row.item.shelves" :key="shelve.id">
                                <b-td>{{ shelve.shelf_name }}</b-td>
                                <b-td>{{ shelve.shelf_num }}</b-td>
                                <b-td>{{ shelve.capacity }}</b-td>
                                <b-td>{{ shelve.active }}</b-td>
                                <b-td>
                                    <b-button @click="editShelve(shelve.id)"
                                              size="sm"
                                              variant="primary"
                                              class="mr-2"
                                              :title="$t('common.title.edit')"
                                    >
                                        <font-awesome-icon icon="edit"/>
                                    </b-button>
                                    <b-button @click="deleteShelve(shelve.id)"
                                              size="sm"
                                              variant="danger"
                                              :title="$t('common.title.delete')"
                                    >
                                        <font-awesome-icon icon="trash"/>
                                    </b-button>
                                </b-td>
                            </b-tr>
                            </tbody>
                        </b-table-simple>
                    </template>
                </b-table>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'ShelvesModal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            data: [],
            fields: [
                {
                    label: this.$t('stock.label.rack_name'),
                    key: 'rack_name',
                    sortable: true,
                },
                {
                    label: this.$t('stock.label.rack_num'),
                    key: 'rack_num',
                    sortable: true,
                },
                {
                    label: this.$t('stock.label.actions'),
                    key: 'actions',
                    sortable: false,
                },
            ],
        }
    },
    methods: {
        ...mapGetters('Warehouses', ['getWarehouse']),
        shown() {
            const dataFetch = this.$store.dispatch('Stock/fetchRacksAndShelves', {
                warehouseId: this.payload.id,
            })

            Promise.all([dataFetch])
                .then((data) => {
                    this.data = data[0]
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        deleteRack(shelve) {
            this.$removeConfirm('Stock/deleteRack', {
                rackNum: shelve.rack_num,
                warehouseId: this.payload.id,
            }, this.shown)
        },
        deleteShelve(id) {
            this.$removeConfirm('Stock/deleteShelve', id, this.shown)
        },
        editShelve(id) {
            this.$root.$children[0].openModal('shelve-modal', {id: id}, this.shown, {width: '800px'})
        },
        createShelves(shelve) {
            this.$root.$children[0].openModal('generate-shelves-modal', {
                rackNum: shelve.rack_num,
                warehouseId: this.payload.id,
            }, this.shown, {width: '800px'})
        }
    }
}
</script>